<template>
    <!-- <div class="page-container">
        <a id="download-pdf" style="display:none;"></a>
        <div class="body winner-typing">
            <div class="container-fluid header-info">
                <div class="row">
                    <div class="col">
                        <h4 class="header-title"><i class="fa fa-handshake" aria-hidden="true"></i>Realizar Pedido</h4>
                        <h5 class="header-subtitle">Cotação #{{$route.params.id}}</h5>
                    </div>
                    <div class="col not-print" v-if="backRoute == 1">
                        <div class="providers-nav">
                            <span class="prev hvr-backward" @click="navigate_provider(-1)">
                                <i class="fas fa-backward"></i>
                            </span>
                            <span class="next hvr-forward" @click="navigate_provider(1)">
                                <i class="fas fa-forward"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <cc-loader :text="'Carregando Fornecedor'" v-if="SECTION == 'TYPINGS' && is_loading_provider"></cc-loader>
                <div class="row summary" v-if="current_subrequest" v-show="!is_loading_provider">
                    <div class="col-4">
                        <div class="info-block">
                            <span class="tag">comprador</span>
                            <span style="color:black !important" class="header">{{ current_subrequest.loj_nomeFantasia.toUpperCase() }}</span>
                            <span>{{ current_subrequest.loj_endereco }}, {{ current_subrequest.loj_bairro }} - {{ current_subrequest.cid_nome }}/{{ current_subrequest.est_sigla }}</span>
                            <span>Fone: <cc-whatsapp :phone="current_subrequest.loj_telefone" /></span>
                            <span>CNPJ: {{ current_subrequest.loj_cnpj | cnpj }}</span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="info-block">
                            <span class="tag">fornecedor</span>
                            <span class="header">
                                <span class="provider">{{ current_subrequest.for_nome }}</span>
                                <span class="rate shadow" @click="show_reviews = true"><i class="fa fa-star" aria-hidden="true"></i> ({{current_subrequest.rate}})</span>
                            </span>
                            <span>{{ current_subrequest.usu_nome }}</span>
                            <span>{{ current_subrequest.usu_email }}</span>
                            <span>Fone: <cc-whatsapp :phone="current_subrequest.usu_telefone" /></span>
                        </div>
                    </div>
                    <div class="col" v-if="current_subrequest.data_entrega">
                        <div class="info-block">
                            <span class="tag">detalhes do pedido</span>
                            <span class="header"></span>
                            <span>Entrega: {{ current_subrequest.data_entrega | formatDateOnly }} </span>
                            <span v-if="current_subrequest.tpc_nome">Tipo Entrega: {{ current_subrequest.tpc_nome }} </span>
                            <span>Faturamento: {{ current_subrequest.faturamento_minimo | currency }}</span>
                            <span>Cond. de Pgto: {{ current_subrequest.forma }}</span>
                            <span>Status:
                                <span v-if="!current_subrequest.id_pedido" class="status pending">
                                    pendente
                                </span>
                                <span v-else class="status ordered">
                                    gerado
                                </span>
                            </span>
                        </div>
                    </div>

                    <div class="col" v-if="current_subrequest && current_subrequest.observacao">
                        <div class="info-block">
                            <span class="tag">observações</span>
                            <div class="text-box">{{ current_subrequest.observacao }}</div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="info-block">
                            <span class="tag controls">
                                Informar observação do pedido
                                <i class="fa fa-edit" @click="request_detail_obs_editing = true" v-if="!request_detail_obs_editing && !current_subrequest.id_pedido"></i>
                                <i class="fa fa-save" @click="save_request_detail_obs" v-if="request_detail_obs_editing"></i>
                                <i class="far fa-times-circle" @click="request_detail_obs_editing = false" v-if="request_detail_obs_editing"></i>
                            </span>
                            <div class="text-box">
                                <textarea :disabled="!request_detail_obs_editing" class="form-input" cols="40" rows="6" v-model="current_subrequest.obs"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row action-buttons not-print" v-if="current_subrequest">
                    <div class="col info">
                        <button style="color:black !important" class="info" disabled>
                            <i class="fas fa-coins"></i>
                            Valor Total: {{ this.current_subrequest.total | currency }}
                        </button>
                        <button style="color:black !important" class="info" disabled :class="{ 'positive-credit' : true }" v-if="can('REQUEST_ORDER', 'CREATE') && current_subrequest && backRoute == 1">
                            Saldo: {{ (current_subrequest.faturamento_minimo > this.current_subrequest.total ? this.current_subrequest.total - current_subrequest.faturamento_minimo : 0) | currency }}
                        </button>
                    </div>
                    <div class="col">
                        <router-link :to="{ name: backRouteName, params: { id: $route.params.id, tab: 'pedidos', request_id: $route.params.id }, query: { po: backRoute, o: backOrdered } }" tag="button" v-bind:class="'back'">
                            <i class="fa fa-arrow-left" style="margin-right: 8px;font-size: 1.5rem;color: #71727d;padding-bottom: 2px;"></i>
                            Voltar
                        </router-link>
                        <router-link v-if="backRoute != 2"
                            v-bind:class="'back'"
                            :to="{
                                name: 'request-matrix-overview',
                                params: {
                                    id: $route.params.id,
                                    sellerid: $route.params.sellerid,
                                    providerid: $route.params.providerid
                                },
                                query: { cli_id: $route.query.cli_id }
                            }"
                            tag="button">
                                <i class="fas fa-store-alt"></i>
                                <span>Por Empresa</span>
                        </router-link>
                        <button v-on:click="exportPdfOrder()">
                            <i class="fas fa-print"></i>
                            <span>Imprimir</span>
                        </button>
                        <button class="order" v-on:click="order" v-if="can('REQUEST_ORDER', 'CREATE') && !current_subrequest.id_pedido && backRoute == 1">
                                <i class="fa fa-shopping-cart"></i>
                                Realizar Pedido
                        </button>
                        <button class="order cancel" v-on:click="cancel_order" v-if="can('REQUEST_ORDER', 'CREATE') && current_subrequest.id_pedido && backRoute == 1">
                            <i class="fa fa-times-circle"></i>
                            Cancelar Pedido
                        </button>
                    </div>
                </div>

                <div class="row search-bar not-print">
                    <div class="col">
                        <span class="fa fa-search"></span>
                        <input v-on:keyup="search" v-model="search_terms" type="search" placeholder="Pesquisar itens do pedido" />
                    </div>

                    <div class="col filter-items">
                        <cc-button
                            :content="'Itens do Pedido'"
                            :classes="{ 'active' : SECTION == 'TYPINGS' }"
                            @click="toggleOrdersOnlyFilter('TYPINGS')"
                        ></cc-button>
                        <cc-button
                            :content="'Todos digitados'"
                            :classes="{ 'active' : SECTION == 'PRODUCTS'  }"
                            :disabled="current_subrequest && current_subrequest.id_pedido != null"
                            @click="toggleOrdersOnlyFilter('PRODUCTS')"
                        ></cc-button>
                    </div>
                </div>

                <div class="row">
                    <div class="col pr-1">
                        <cc-typings
                            v-if="SECTION == 'TYPINGS' && !is_loading_provider"
                            :key="$route.params.sid"
                            :subrequest="current_subrequest"
                            @reload_provider="reload_current_provider"></cc-typings>
                        <cc-products
                            v-if="SECTION == 'PRODUCTS'"
                            v-bind:products="products"
                            v-bind:sellers="sellers"
                            v-bind:loj_id="$route.params.sid"
                            v-bind:seller_id="$route.params.sellerid"
                            v-bind:is_loading="is_loading"
                            @loadProviders="reload_current_provider"
                        ></cc-products>
                        <ProductsMatrixComponent
                            v-if="SECTION == 'MATRIX'"
                            :id="$route.params.id"
                            :min_fat="current_subrequest && current_subrequest.faturamento_minimo ? parseFloat(current_subrequest.faturamento_minimo) : 0"
                            :seller_id="$route.params.sellerid"
                            :provider="current_subrequest"
                            :reload="trigger"
                            :is_loading="is_loading"
                            @update="update"
                        ></ProductsMatrixComponent>
                        <cc-seller-review
                            :seller="{ usu_id: current_subrequest.vendedor_id, usu_nome: current_subrequest.usu_nome }"
                            v-if="show_reviews"
                            @close="show_reviews = false"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div v-if="is_loading_provider">
        <cc-loader-full />
    </div>
    <div v-else class="makeorder-page">
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Monitor de Cotação
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <router-link :to="'/cliente/monitores/vencedores/' + $route.params.id" class="page-route-select-text">
                Comparativo de Preço
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">
                Não Digitados
            </div>
        </div>
        <div class="makeorder-page-title-container">
            <div class="makeorder-page-title">
                <div>Realizar Pedido</div>
                <div class="makeorder-page-subtitle">Cotação #{{$route.params.id}}</div>
            </div>
            <div>
                <span class="material-icons-outlined makeorder-page-title-icons" @click="navigate_provider(-1)">skip_previous</span>
                <span class="material-icons-outlined makeorder-page-title-icons" @click="navigate_provider(1)" style="margin-left: 1vw;">skip_next</span>
            </div>
        </div>
        <div class="makeorder-page-info-container">
            <div class="makeorder-page-info-texts">
                <div class="makeorder-page-info-buyer-colum">
                    <div class="makeorder-page-info-buyer-title-text">Comprador</div>
                    <div class="makeorder-page-info-first-line makeorder-page-info-subtitle">Comprador:</div>
                    <div class="makeorder-page-info-text">{{ current_subrequest.loj_nomeFantasia.toUpperCase() }}</div>
                    <div class="makeorder-page-info-text">{{ current_subrequest.loj_endereco }}, {{ current_subrequest.loj_bairro }} - {{ current_subrequest.cid_nome }}/{{ current_subrequest.est_sigla }}</div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">Fone:</div>
                        <a class="makeorder-page-info-text" v-if="current_subrequest.loj_telefone" :href="'https://api.whatsapp.com/send?phone='+ formatNumber(current_subrequest.loj_telefone)" target="_blank">{{ current_subrequest.loj_telefone | VMask('(##) #####-####')  }}</a>
                        <div class="makeorder-page-info-text" v-else>--</div>
                    </div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">CNPJ:</div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.loj_cnpj | cnpj }}</div>
                    </div>
                </div>
                <div class="makeorder-page-info-suplier-colum">
                    <div class="makeorder-page-info-buyer-title-text">Fornecedor</div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-suplier-raiting-text">{{ current_subrequest.for_nome }}</div>
                        <span class="material-icons-outlined makeorder-page-info-suplier-raiting-icon">grade</span>
                        <div>{{current_subrequest.rate.toFixed(1)}}</div>
                    </div>
                    <div class="makeorder-page-info-text">{{ current_subrequest.usu_nome }}</div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">E-mail:</div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.usu_email }}</div>
                    </div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">Fone:</div>
                        <img class="makeorder-page-info-whats-icon" src="@/assets/WhatsApp_black.svg" alt="mandar mensagem">
                        <a class="makeorder-page-info-text" v-if="current_subrequest.usu_telefone" :href="'https://api.whatsapp.com/send?phone='+ formatNumber(current_subrequest.usu_telefone)" target="_blank">{{ current_subrequest.usu_telefone | VMask('(##) #####-####')  }}</a>
                        <div class="makeorder-page-info-text" v-else>--</div>
                    </div>
                </div>
                <div class="makeorder-page-info-details-colum">
                    <div class="makeorder-page-info-buyer-title-text">Detalhe do Pedido</div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">Entrega:</div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.data_entrega | formatDateOnly }}</div>
                    </div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">Tipo de Entrega:</div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.tpc_nome }}</div>
                    </div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">Faturamento:</div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.faturamento_minimo | currency }}</div>
                    </div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">Cond. de Pgto:</div>
                        <div class="makeorder-page-info-text">{{ current_subrequest.forma }}</div>
                    </div>
                    <div class="makeorder-page-info-suplier-raiting">
                        <div class="makeorder-page-info-subtitle">Status:</div>
                        <div v-if="!current_subrequest.id_pedido" class="makeorder-page-info-status-pending">pendente</div>
                        <div v-else class="makeorder-page-info-status-created">gerado</div>
                    </div>
                </div>
            </div>
            
            <div class="makeorder-page-info-obs-colum">
                <div class="makeorder-page-info-buyer-title-text makeorder-page-info-obs-title-container">
                    Observação
                    <span @click="save_request_detail_obs" class="material-icons-outlined makeorder-page-info-obs-title-icon">save</span>
                </div>
                <textarea class="makeorder-page-info-obs-textarea" placeholder="Digite aqui ser houver alguma observação" cols="30" rows="8" v-model="current_subrequest.obs"></textarea>
            </div>
        </div>
        <div class="makeorder-page-buttons-row">
            <div class="makeorder-page-buttons-total">
                <span class="material-icons-outlined makeorder-page-buttons-total-icon">attach_money</span>
                Valor Total: {{ this.current_subrequest.total | currency }}
            </div>
            <div class="makeorder-page-buttons-balance">
                Saldo: {{ (current_subrequest.faturamento_minimo > this.current_subrequest.total ? this.current_subrequest.total - current_subrequest.faturamento_minimo : 0) | currency }}
            </div>
            <div class="makeorder-page-buttons-blank"></div>
            <StandardButton :action="sendBack" :iconName="'arrow_back'" :text="'Voltar'"/>
            <StandardButton class="makeorder-page-button-right" :action="sendSuplier" :iconName="'store'" :text="'Por Empresa'"/>
            <StandardButton class="makeorder-page-button-right" :action="exportPdfOrder" :iconName="'print'" :text="'Imprimir'"/>
            <div class="makeorder-page-button-order" v-on:click="order" v-if="can('REQUEST_ORDER', 'CREATE') && !current_subrequest.id_pedido && backRoute == 1">
                <span class="material-icons-outlined makeorder-page-button-order-icon">shopping_cart</span>
                Realizar Pedido
            </div>
            <div class="makeorder-page-button-cancelorder" v-on:click="cancel_order" v-if="can('REQUEST_ORDER', 'CREATE') && current_subrequest.id_pedido && backRoute == 1">
                <span class="material-icons-outlined makeorder-page-button-cancelorder-icon">block</span>
                Cancelar Pedido
            </div>
        </div>
        <div class="makeorder-page-buttons-row">
            <StandardInput width="40%" type="text" class="desktop" :action="searchProducts" placeholder="Digite o nome do Produto"/>
            <StandardInput width="100%" type="text" class="mobile" :action="searchProducts" placeholder="Digite o nome do Produto"/>
            <div class="makeorder-page-buttons-blank"></div>
            <div class="makeorder-page-buttons-section" @click="toggleOrdersOnlyFilter('TYPINGS')" :class="getActiveSection('TYPINGS')" style="cursor: pointer;">Itens do Pedido</div>
            <div class="makeorder-page-buttons-section" @click="toggleOrdersOnlyFilter('PRODUCTS')" :class="getActiveSection('PRODUCTS')" style="margin-left: 1vw; cursor: pointer;">Todos Digitados</div>
        </div>
        <div class="makeorder-page-table-container">
            <cc-typings
                v-if="SECTION == 'TYPINGS' && !is_loading_provider"
                :key="$route.params.sid"
                :subrequest="current_subrequest"
                @reload_provider="reload_current_provider"
                :filterProducts="search_terms"></cc-typings>
            <cc-products
                v-if="SECTION == 'PRODUCTS'"
                v-bind:products="products"
                v-bind:sellers="sellers"
                v-bind:loj_id="$route.params.sid"
                v-bind:seller_id="$route.params.sellerid"
                v-bind:is_loading="is_loading"
                @loadProviders="reload_current_provider"
            ></cc-products>
            <ProductsMatrixComponent
                v-if="SECTION == 'MATRIX'"
                :id="$route.params.id"
                :min_fat="current_subrequest && current_subrequest.faturamento_minimo ? parseFloat(current_subrequest.faturamento_minimo) : 0"
                :seller_id="$route.params.sellerid"
                :provider="current_subrequest"
                :reload="trigger"
                :is_loading="is_loading"
                @update="update"
            ></ProductsMatrixComponent>
        </div>
        <a id="download-pdf" style="display:none;"></a> 
    </div>
</template>
<script>
import Button from "@/components/ui/buttons/button.component"
import Pagination from "@/components/cliente/base-components/Pagination";
import Typings from "@/components/lists/typings.componentV2";
import ProductsMatrixComponent from "./_products-matrix.component";
import * as CONSTANTS from "@/constants/constants";
import QuotationService from "@/services/QuotationService";
import OrderService from "@/services/OrderService";
import RequestsService from "@/services/v2/requests.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import popoverLastBuy from "@/components/popovers/last-buy.popover";
import WinnerProducts from "@/components/partials/_products";
import SellerReviewModal from '@/modals/seller-review/seller-review.modal';
import StandardInput from '@/components/ui/inputs/StandardInputAuto.vue'
import StandardButton from '@/components/ui/buttons/StandardButton.vue'
import { perm_mixin } from '@/mixins/permission.mixin'

export default {
    mixins: [ perm_mixin ],
    props: {
        SECTION: {
            type: String,
            default: 'TYPINGS'
        }
    },
    components: {
        ccButton: Button,
        ccPagination: Pagination,
        ccProducts: WinnerProducts,
        ProductsMatrixComponent,
        ccTypings: Typings,
        popoverLastBuy,
        'cc-seller-review' : SellerReviewModal,
        StandardButton,
        StandardInput
    },
    data() {
        return {
            trigger: false,
            is_loading: false,
            is_loading_provider: false,
            show_reviews: false,
            request_detail_obs_editing: false,
            CONSTANTS: CONSTANTS,
            backRoute: 1,
            backOrdered: 0,
            backRouteName: "cliente-monitores-vencedores",
            sellers: [],
            openedPopovers: [],
            orderService: new OrderService(),
            quotationService: new QuotationService(),
            service: new RequestsService(),
            current_subrequest: null,
            search_terms: null,
            selected_status_filter: "WINNERS_ONLY",
            quotationId: [],
            subrequests: [],
            all_subrequests: [],
            products: [],
            breadcrumbs: [
                {
                    link: CONSTANTS.ROUTE_MONITORS,
                    name: "Monitor de Cotações"
                },
                {
                    link: CONSTANTS.ROUTE_MONITORS + "/vencedores/" + this.$route.params.id,
                    name: "Cotação " + this.$route.params.id
                },
                {
                    name: "Pré-Pedidos"
                },
                {
                    name: "Realizar Pedido"
                }
            ]
        }
    },
    methods: {
        formatNumber(number){
            if (!number) return null
            number = number.replace('+','').replace('(','').replace(')','').replace('-','')
            if (number[0] + '' + number[1] != '55' ) number = '55' + number
            return number
        },
        getActiveSection (text) {if (text == this.SECTION) return 'makeorder-page-buttons-section-active'},
        searchProducts(value) { this.search_terms = value },
        sendSuplier() {this.$router.push({ name: 'request-matrix-overview', params: { id: this.$route.params.id, sellerid: this.$route.params.sellerid, providerid: this.$route.params.providerid }, query: { cli_id: this.$route.query.cli_id }})},
        sendBack() {this.$router.push({ name: this.backRouteName, params: { id: this.$route.params.id, tab: 'pedidos', request_id: this.$route.params.id }, query: { po: this.backRoute, o: this.backOrdered } })},
        toggleOrdersOnlyFilter(toggle) {
            this.SECTION = toggle;
            if(this.SECTION == 'PRODUCTS') {
                this.getProducts(this.$route.params.sid)
            } else if(this.SECTION == 'PRODUCTS') {
                this.is_loading = false
            }
        },
        print() {
            self.print();
        },
        openPopover(popover) {
            this.openedPopovers.push(popover);
            this.$refs[popover][0].style.display = "block";
        },
        closePopover(popover) {
            const index = this.openedPopovers.indexOf(popover);
            if (index > -1) {
                this.openedPopovers.splice(index, 1);
            }
            this.$refs[popover][0].style.display = "none";
        },
        exportPdfOrder() {
            if(this.SECTION == 'TYPINGS') {
                let callback = data => {
                    var file = new Blob([data], { type: "application/pdf" });
                    var fileURL = URL.createObjectURL(file);
                    var el = document.getElementById("download-pdf");
                    var title = "Pedido-" + this.current_subrequest.id_pedido + ".pdf";
                    if(!this.current_subrequest.id_pedido) {
                        title = "pre-pedido-vendedor.pdf";
                    }
                    el.download = title
                    el.href = fileURL;
                    el.click();
                    setTimeout(function() {
                        window.URL.revokeObjectURL(fileURL);
                    }, 100);
                    this.is_loading_pdf = false;
                }

                let data = {
                    id_pedido: this.current_subrequest.id_pedido,
                    id_cotacao: this.$route.params.id,
                    id_loja: this.current_subrequest.loj_id,
                    usu_id: this.current_subrequest.vendedor_id
                }

                this.is_loading_pdf = true;
                this.orderService.exportPdfOrder(data).then(response => response.data)
                .then(callback).catch(error => {
                    this.is_loading_pdf = false;
                    ErrorHandlerService.handle(error, this.$store);
                });
            } else {
                self.print()
            }
        },
        toggle_winner(typing, product, pro_observacao, sellerId = this.$route.params.sellerid, prod_id, will_be_winner = !this.is_winner(product)) {
            this.$set(typing, 'loading', true);
            this.quotationService.toggleQuotationWinner(
                this.$route.params.id,
                this.$route.params.sid,
                prod_id,
                sellerId,
                will_be_winner
            ).then(response => response.data).then(data => {
                this.quotationService.getDigitacaesProdutoComparativo(
                    this.$route.params.id,
                    this.$route.params.sid,
                    product.idProduto
                ).then(response => response.data)
                .then(data => {
                    this.$set(product, "digitacoes", data.digitacoes);
                    this.$set(typing, 'loading', false);
                })
            })
        },
        order() {

            let amount = ((this.current_subrequest.total < (this.current_subrequest.faturamento_minimo || 0)) ? this.current_subrequest.total - this.current_subrequest.faturamento_minimo : 0);

            let confirmation_text = "<h2 style=''>Confirma realização do pedido?</h2>"
            confirmation_text += "</br><span>Seu pedido será enviado ao fornecedor!</span>"

            if(amount < 0) {
                confirmation_text = "<h2 style='color:#d33'>Atenção! Faturamento minimo não atingido</h2>"
                confirmation_text += "</br><span>Deseja continuar?</span>"
            }

            let data = {
                idcotacao: this.$route.params.id,
                idvendedor: this.$route.params.sellerid,
                loja: this.$route.params.sid,
                obs: this.current_subrequest.obs
            };
            this.$swal.fire({
                html: confirmation_text,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, realizar pedido!"
            }).then(result => {
                if (result.value) {
                    this.is_loading = true;
                    if(this.SECTION == 'MATRIX') {
                        delete data["loja"]
                        this.request_order(data).then(() => this.trigger = !this.trigger)
                    } else {
                        this.request_order(data)
                    }
                }
            })
        },
        request_order(data, prevent_feedback = false) {
            return this.quotationService.order(data).then(response => response.data)
            .then(data => {
                if(!prevent_feedback){
                    this.is_loading = false;
                    this.$swal.fire(
                        "Realizado!",
                        "Seu pedido foi realizado com sucesso.",
                        "success"
                    )
                    this.update();
                }
            }, err => {
                this.is_loading = false;
                this.$swal.fire(
                    "Erro!",
                    "Ocorreu um erro ao realizar o pedido.",
                    "error"
                )
            })
        },
        save_request_detail_obs() {
            let data = {
                obs: this.current_subrequest.obs,
                loj_id: this.current_subrequest.loj_id,
                usu_id: this.current_subrequest.vendedor_id
            }
            return this.service.save_pre_request_details(this.$route.params.id, data).then(response => response.data)
            .then(data => {
                this.request_detail_obs_editing = false
                this.$swal.fire(
                    "Salvo!",
                    "Suas observações foram salvas com sucesso!",
                    "success"
                )
            }, err => {
                this.$swal.fire(
                    "Erro!",
                    "Ocorreu um erro ao salvar observações.",
                    "error"
                )
            })
        },
        navigate_provider(direction) {
            var s = null;
            for (var i = 0; i < this.subrequests.length; i++) {
                s = this.subrequests[i];
                if (s.vendedor_id == this.$route.params.sellerid && s.loj_id == this.$route.params.sid && s.for_id == this.$route.params.providerid) {
                    if (direction == 1) {
                        if (this.subrequests.length > i + 1) {
                            s = this.subrequests[i + 1];
                            break;
                        } else if (this.subrequests.length == i + 1) {
                            s = this.subrequests[0];
                            break;
                        }
                    } else if (direction == -1) {
                        if (i == 0) {
                            s = this.subrequests[this.subrequests.length - 1];
                        } else {
                            s = this.subrequests[i - 1];
                        }
                        break;
                    }
                }
            }
            this.$router.push({
                name: "lancamento-cliente-monitores-vencedores",
                params: {
                    id: this.$route.params.id,
                    sellerid: s.vendedor_id,
                    sid: s.loj_id,
                    providerid: s.for_id
                }
            });
            this.trigger = !this.trigger
            this.update();
        },
        cancel_order() {
            let data = {
                numeroCotacao: `${this.$route.params.id}`,
                vendedor: `${this.$route.params.sellerid}`,
                loja: `${this.$route.params.sid}`
            };
            this.$swal.fire({
                title: "Confirma cancelamento do pedido?",
                text: "Seu pedido será cancelado!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, cancelar pedido!"
            }).then(result => {
                if (result.value) {
                    this.is_loading = true
                    if(this.SECTION == 'MATRIX') {
                        this.all_subrequests.forEach(sub => {
                            if(sub.vendedor_id == data.vendedor && sub.id_pedido && sub.for_id == this.$route.params.providerid) {
                                console.log(sub.loj_id)
                                console.log(sub)
                            }
                        })
                        let subs = this.all_subrequests.filter(sub => sub.vendedor_id == data.vendedor && sub.id_pedido && sub.for_id == this.$route.params.providerid)
                        subs.forEach((sub, k) => {
                            this.request_order_cancelling({...data, loja: `${sub.loj_id}` }, k+1 == subs.length).then(() => {
                                if(k+1 == subs.length) {
                                    this.trigger = !this.trigger
                                }
                            })
                        })
                    } else {
                        this.request_order_cancelling(data)
                    }
                }
            });
        },
        request_order_cancelling(data, prevent_feedback = false) {
            return this.quotationService.cancel_order(data).then(response => response.data)
            .then(data => {
                if(!prevent_feedback) {
                    this.$swal.fire(
                        "Sucesso!",
                        "Seu pedido foi cancelado com sucesso.",
                        "success"
                    )
                    this.is_loading = false
                    this.update();
                }
            }, err => {
                this.is_loading = false
                this.$swal.fire(
                    "Erro!",
                    "Ocorreu um erro ao cancelar o pedido.",
                    "error"
                )
            })
        },
        reload_current_provider() {
            this.load_subrequests()
            .catch(error => ErrorHandlerService.handle(error, this.$store))
        },
        checkReturnRoute() {
            if(this.current_subrequest) {
                if(!this.current_subrequest.id_pedido) {
                    this.backOrdered = 0;
                } else if(this.current_subrequest.id_pedido) {
                    this.backOrdered = 1;
                }
            }
        },
        search(evt) {
            this.products.forEach(p => {
                if (new RegExp(this.search_terms, "i").test(p.descricaoProduto) && this.is_winner(p)) {
                    this.$set(p, "visible", true);
                } else {
                    this.$set(p, "visible", false);
                }
            })
        },
        getProducts(store_id = this.$route.params.sid) {
            this.is_loading = true;
            this.products = [];
            this.service.request_report_for_seller_by_store(this.$route.params.id, this.$route.params.sid)
            .then(response => response.data)
            .then(data => {
                for (var i = 0; i < data.produtos.length; i++) {
                    var embalagem_split = data.produtos[i].embalagem.split(" ");
                    data.produtos[i].embalagem_units = embalagem_split[0];
                    data.produtos[i].embalagem_quantity = embalagem_split[1];
                }
                this.total_items = data.valorTotal.totalProdutos;
                this.is_loading = false;
                this.products = data.produtos;
                this.filter_products(this.products)
                this.process_winner_condition(this.products)
            }).catch(error => {
                this.is_loading = false;
                if(error.request && error.request.status == 403) {
                    this.$router.push(CONSTANTS.ROUTE_MONITORS);
                }
                ErrorHandlerService.handle(error, this.$store);
            })
        },
        process_winner_condition(products) {
            this.service.winners_map_for(this.$route.params.id, this.$route.params.sid, this.$route.params.sellerid, this.$route.params.providerid)
            .then(response => response.data).then(data => {
                products.forEach(p => {
                    p.vencedores.forEach(winner => {
                        winner.vencedor_quantidade = data.some(offer => offer.prod_id == winner.pro_id && offer.vendedor_id == winner.idVendedor) ? 1 : 0
                        winner.new_value = winner.valor
                        winner.new_offer_quantity = p.flag_generico == 1 ? winner.embalagem : p.qtd_embalagem
                    })
                })
            })
        },
        filter_products(products) {
            if(products) {
                Object.values(products).forEach(p => {
                    p.visible = p.vencedores.some(winner => winner.idVendedor == this.$route.params.sellerid && winner.idFornecedor == this.$route.params.providerid)
                })
            }
        },
        update(section = null) {
            // this.loading = true
            this.is_loading_provider = true;
            if(section) {
                this.SECTION = section;
            }
            this.load_subrequests().then(() =>{
                this.checkReturnRoute();
                this.$forceUpdate();
                // this.loading = false
                this.is_loading_provider = false;
                if(this.current_subrequest && this.SECTION == 'PRODUCTS') {
                    this.SECTION = 'TYPINGS'
                }
            })
            .catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        load_subrequests() {
            return this.service.subrequests_with_sellers_for(this.$route.params.id)
            .then(response => response.data).then(data => {
                this.subrequests = data;
                this.all_subrequests = data
                let next = this.subrequests.find(s => s.for_id == this.$route.params.providerid && s.vendedor_id == this.$route.params.sellerid && s.loj_id == this.$route.params.sid)
                if(next) {
                    this.current_subrequest = next
                    this.sort_subrequests()
                } else {
                    if(!this.current_subrequest) {
                        this.$router.push( { name: 'cliente-monitores-vencedores', params: { id: this.$route.params.id }, query: { po: 1 } });
                    }
                    this.subrequests.push(this.current_subrequest)
                    this.sort_subrequests()
                    this.current_subrequest.total = 0
                }
            })
        },
        sort_subrequests() {
            let result = []
            let loj_ids = Array.from(new Set(this.subrequests.map(s => s.loj_id)))
            for(let id of loj_ids) {
                result = result.concat(this.subrequests.filter(s => s.loj_id == this.$route.params.sid && s.total < s.faturamento_minimo).sort((a, b) => {
					let total1 = a.total
					let total2 = b.total
					if (total1 > total2) return 1
					if (total2 > total1) return -1
					return 0
                }))
                result = result.concat(this.subrequests.filter(s => s.loj_id == this.$route.params.sid && s.total >= s.faturamento_minimo).sort((a, b) => {
					let total1 = a.total
					let total2 = b.total
					if (total1 > total2) return 1
					if (total2 > total1) return -1
					return 0
				}))
            }
            this.subrequests = result;
        },
        update_breadcrumbs() {
            this.$store.dispatch('set_breadcrumbs', [
                { name: "Painel de Operações", route: 'monitors-page' },
                { name: "Cotações", route: 'monitors-page' },
                { name: `#${this.$route.params.id}`, route: 'cliente-monitores-vencedores' },
                { name: "detalhes" }
            ])
        }
    },
    created() {
        this.update();
        this.update_breadcrumbs()
        if (this.$route.query.backRoute == 2) {
            this.backRoute = 2;
            this.backRouteName = this.$route.params.backRouteName;
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "./details.page";
    .makeorder-page{
        padding-right: 3.5vw;
        font-size: 1vw;
    }
    .page-route-select{
      display: flex;
      align-items: center;
    }
    .page-route-select-text{
      font-weight: 400;
      font-size: 1.36em;
      line-height: 17px;
      color: #ABABAB;
      margin-right: 20px;
      cursor: pointer;
    }
    .page-route-select-icon{
      color: #FF9954;
      margin-right: 20px;
    }
    .mobile{display: none;}
    .makeorder-page-title{
        font-weight: 700;
        font-size: 2.72em;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #605F5F;
    }
    .makeorder-page-title-container{
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
    }
    .makeorder-page-title-icons{
        border: 2px solid #FF7110;
        color: #FF7110;
        padding: 0.5vh 1vw;
        border-radius: 8px;
        cursor: pointer;
        &:hover{
            background-color: #FF7110;
            color: white;
        }
    }
    .makeorder-page-info-container{
        display: flex;
        margin-top: 5vh;
        padding-left: 5vw;
    }
    .makeorder-page-info-buyer-colum{
        flex: 1;
    }
    .makeorder-page-info-suplier-colum{
        flex: 1;
    }
    .makeorder-page-info-details-colum{
        flex: 1;
    }
    .makeorder-page-info-obs-colum{
        width: 25%;
    }
    .makeorder-page-info-buyer-title-text{
        font-weight: 700;
        font-size: 1.4em;
        letter-spacing: 0.15px;
        color: #606060;
    }
    .makeorder-page-info-first-line{ margin-top: 2vh; }
    .makeorder-page-info-subtitle{
        font-weight: 500;
        font-size: 1.15em;
        letter-spacing: 0.15px;
        color: #707070;
        margin-right: 0.5vw;
    }
    .makeorder-page-info-text{
        font-weight: 300;
        font-size: 1.15em;
        letter-spacing: 0.15px;
        color: #A1A1A1;
    }
    .makeorder-page-info-suplier-raiting{
        margin-top: 1.5vh;
        display: flex;
        align-items: center;
        margin-bottom: 1vh;
    }
    .makeorder-page-info-suplier-raiting-text{
        background: rgba(42, 205, 114, 0.1);
        border-radius: 8px;
        padding: 0.5vh 0.5vw;
        font-weight: 300;
        font-size: 1.1em;
        color: #30AA4C;
        margin-right: 2vw;
    }
    .makeorder-page-info-suplier-raiting-icon{
        color: #FFCA2B;
        font-size: 2em;
    }
    .makeorder-page-info-whats-icon{
        filter: brightness(0) saturate(100%) invert(53%) sepia(11%) saturate(2678%) hue-rotate(81deg) brightness(100%) contrast(89%);
        height: 3.5vh;
        margin-right: 1.5vw;
    }
    .makeorder-page-info-status-created{
        font-weight: 500;
        font-size: 1.1em;
        color: #2ACD72;
    }
    .makeorder-page-info-status-pending{
        font-weight: 500;
        font-size: 1.1em;
        color: #f39c12;
    }
    .makeorder-page-info-obs-textarea{
        resize: none;
        padding: 1vh 1vw;
        border-radius: 8px;
        border: 1px solid #F2F2F2;
        margin-top: 0.5vh;
    }
    .makeorder-page-info-obs-textarea:focus{outline: none;}
    .makeorder-page-info-obs-title-container{
        display: flex;
    }
    .makeorder-page-info-obs-title-icon{
        font-size: 1.4em;
        margin-left: 1vw;
        color: lightgreen;
        cursor: pointer;
    }
    .makeorder-page-buttons-row{
        display: flex;
        margin-top: 3.5vh;
    }
    .makeorder-page-buttons-total{
        display: flex;
        padding: 1vh 1vw;
        border: 0.5px solid #E5E5E5;
        border-radius: 8px;
        align-items: center;
        font-weight: 600;
        font-size: 1.1em;
        letter-spacing: 0.05em;
        color: #202020;
        margin-right: 2vw;
    }
    .makeorder-page-buttons-total-icon{
        font-size: 1.75em;
        color: #FF7110;
    }
    .makeorder-page-buttons-balance{
        border: 1px solid #FF7110;
        border-radius: 8px;
        font-weight: 600;
        font-size: 1.1em;
        letter-spacing: 0.05em;
        color: #FF7110;
        padding: 1vh 1vw;
        display: flex;
        align-items: center;
    }
    .makeorder-page-buttons-blank{flex: 1;}
    .makeorder-page-button-right{margin-left: 1.5vw;}
    .makeorder-page-buttons-section{
        background-color: white;
        font-weight: 600;
        font-size: 1.1em;
        color: #202020;
        border-radius: 8px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        cursor: pointer;
        height: 7.5vh;
        min-width: 12vw;
        padding: 0 1vw;
        display: flex;
        align-items: center;
        &:hover{
            background-color: #fdf0e9;
            color: #FF7110;
        }
    }
    .makeorder-page-buttons-section-active{
        background-color: #fdf0e9;
        color: #FF7110;
    }
    .makeorder-page-button-order{
        background-color: #30AA4C;
        border: 0.5px solid #E5E5E5;
        border-radius: 8px;
        font-weight: 600;
        font-size: 1.1em;
        letter-spacing: 0.05em;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        margin-left: 1.5vw;
        padding: 0 1vw;
        cursor: pointer;
    }
    .makeorder-page-button-order:hover{
        background-color: white;
        color: #30AA4C;
        border: 0.5px solid #30AA4C;
    }
    .makeorder-page-button-order-icon{
        margin-right: 0.5vw;
        font-size: 1.5em;
    }
    .makeorder-page-button-cancelorder{
        background-color: #E5E5E5;
        border: 0.5px solid #E5E5E5;
        border-radius: 8px;
        font-weight: 600;
        font-size: 1.1em;
        letter-spacing: 0.05em;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        margin-left: 1.5vw;
        padding: 0 1vw;
        cursor: pointer;
    }
    .makeorder-page-button-cancelorder-icon{
        margin-right: 0.5vw;
        font-size: 1.5em;
    }
    .makeorder-page-table-container{margin-top: 3vh;}
    .makeorder-page-info-texts{
        display: flex;
        flex: 1;
    }
    .makeorder-page-subtitle{
        font-weight: 500;
        font-size: 0.6em;
        letter-spacing: 0.15px;
        color: #605F5F;
        margin-top: 1vh;
    }
    @media only screen and (max-width: 1000px) {
        .makeorder-page{font-size: 1.25vw;}
        .makeorder-page-info-container{flex-direction: column;}
    }
    @media only screen and (max-width: 750px) {
        .makeorder-page{font-size: 1.5vw;}
    }
    @media only screen and (max-width: 550px) {
        .mobile{display: block;}
        .desktop{display: none;}
        .makeorder-page{font-size: 2vw;}
        .makeorder-page-buttons-row{flex-direction: column;}
        .makeorder-page-button-right{margin-left: 0; margin-top: 2vh}
        .makeorder-page-buttons-total{
            margin-right: 0; 
            margin-bottom: 2vh; 
            justify-content: center; 
            height: 7.5vh
        }
        .makeorder-page-buttons-balance{margin-bottom: 2vh; justify-content: center; height: 7.5vh}
        .makeorder-page-button-order{
            margin-left: 0;
            margin-top: 2vh;
            justify-content: center;
            height: 7.5vh
        }
        .makeorder-page-buttons-section{margin: 2vh 0 !important; justify-content: center;}
    }
    @media only screen and (max-width: 450px) {
        .makeorder-page{font-size: 3vw;}
        .makeorder-page-info-texts{flex-direction: column;}
        .page-route-select{flex-wrap: wrap;}
        .makeorder-page-info-container{
            font-size: 3.5vw;
        }
    }
    </style>
