<template>
    <!-- <cc-modal :modal="modal" @close="close">
        <div slot="body" class="container-fluid" style="min-height:60vw">
            <cc-loader v-show="loading" />
            <div class="row" id="modalBodyPrint" v-show="!loading">
                <div class="col">
                    <table class="table shadow">
                        <thead>
                            <tr>
                                <th class="pl-3">Marca</th>
                                <th>Fornecedor</th>
                                <th>Data Compra</th>
                                <th>Número Cotação</th>
                                <th>N. Requisitantes</th>
                                <th>Quantidade</th>
                                <th>Valor Unit</th>
                                <th>Valor Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="order in orders">
                                <tr v-for="item in order.items" :key="order.id + '-' + item.id">
                                    <td>{{ item.product.brand ? item.product.brand : '-' }}</td>
                                    <td>{{ order.provider ? order.provider.name : '-' }}</td>
                                    <td>{{ order.created_at | moment("DD/MM/YYYY") }}</td>
                                    <td>
                                        <b>#{{ order.request.id }}</b>
                                    </td>
                                    <td>{{ order.requisitants_count }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.unit_price | currency }}</td>
                                    <td>{{ item.packing_price * item.quantity | currency }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </cc-modal> -->
    <cc-loader-full v-if="loading"/>
    <div v-else class="modal-mask">
        <div class="modal-content-container">
            <div class="modal-product-history-header">
                Histório de compra: {{product_name}}
                <span class="material-icons modal-product-history-close" v-on:click="close()">cancel</span>
            </div>
            <div style="overflow-x: auto;">
                <div class="modal-product-history-body">
                    <div class="modal-product-history-table">
                        <div class="modal-product-history-table-header">
                            <div class="modal-product-history-table-width">Marca</div>
                            <div class="modal-product-history-table-width">Fornecedor</div>
                            <div class="modal-product-history-table-width">Data Compra</div>
                            <div class="modal-product-history-table-width">Nº Cotação</div>
                            <div class="modal-product-history-table-width">Nº Requisitante</div>
                            <div class="modal-product-history-table-width">Quantidade</div>
                            <div class="modal-product-history-table-width">Valor Unit.</div>
                            <div class="modal-product-history-table-width">Valor Total</div>
                        </div>
                        <div v-for="(order, idx) in orders">
                            <div :class="getSpecialBackground(idx)" class="modal-product-history-table-row" v-for="item in order.items" :key="order.id + '-' + item.id">
                                <div class="modal-product-history-table-width">{{ item.product.brand ? item.product.brand : '-' }}</div>
                                <div class="modal-product-history-table-width">{{ order.provider ? order.provider.name : '-' }}</div>
                                <div class="modal-product-history-table-width">{{ order.created_at | moment("DD/MM/YYYY") }}</div>
                                <div class="modal-product-history-table-width">{{ order.request.id }}</div>
                                <div class="modal-product-history-table-width">{{ order.requisitants_count }}</div>
                                <div class="modal-product-history-table-width">{{ item.quantity }}</div>
                                <div class="modal-product-history-table-width">{{ item.unit_price | currency }}</div>
                                <div class="modal-product-history-table-width">{{ item.packing_price * item.quantity | currency }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import OrderService from "@/services/v3/products/order.service";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import ErrorHandlerService from "@/services/ErrorHandlerService";

export default {
    mixins: [ loaderMixin ],
    props: {
        product_id: {
            type: Number,
            required: true
        },
        product_name: {
            type: String
        }
    },
    data() {
        return {
            loading: true,
            svc: new OrderService(),
            orders: [],
            modal: {
                title: `Compras do produto`,
                subtitle: this.product_name,
                style: {
                    width: "80%"
                }
            }
        }
    },
    methods: {
        getSpecialBackground (index) {
            if (index % 2 != 0) return 'page-table-line-special'
        },
        close() {
            this.$emit("close");
        },
        load_orders() {
            this.loading = true;
            return this.svc.load_orders(this.product_id).then((response) => {
                this.loading = false;
                this.orders = response.data
            }).catch(err => {
                ErrorHandlerService.handle(error, this.$store);
            })
        }
    },
    created() {
        this.load_orders()
    }
};
</script>

<style lang="scss" scoped>
	@import "./index";
    .modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease;
    font-size: 1vw;
    }
    .modal-content-container{
    background-color: white;
    width: 95vw;
    border-radius: 12px;
    }
    .modal-product-history-header{
        background: #FF7110;
        border-radius: 10px 10px 0px 0px;
        padding: 1vh 1vw;
        color: white;
        font-weight: 500;
        font-size: 2.2em;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .modal-product-history-close{
        font-size: 1.5em;
        cursor: pointer;
    }
    .modal-product-history-body{
        padding: 2vh 1vw;
        min-width: 900px;
    }
    .modal-product-history-title{
        font-weight: 400;
        font-size: 1.5em;
        letter-spacing: 0.15px;
        color: #707070;
        margin-bottom: 2vh;
    }
    .modal-product-history-table-header{
        display: flex;
        background: #FFFEFC;
        border: 0.5px solid #E5E5E5;
        font-weight: 400;
        font-size: 1.2em;
        color: #605F5F;
        padding: 1vh 1vw;
    }
    .modal-product-history-table-width{flex: 1;}
    .modal-product-history-table{
        max-height: 75vh;
        overflow-y: auto;
    }
    .modal-product-history-table-row{
        display: flex;
        padding: 1vh 1vw;
        font-weight: 300;
        font-size: 1.2em;
        color: #605F5F;
        align-items: center;
    }
    .page-table-line-special {background: #F7F7F7;}
    @media only screen and (max-width: 900px) {.modal-mask{font-size: 1.5vw;}}
    @media only screen and (max-width: 650px) {.modal-mask{font-size: 2vw;}}
    @media only screen and (max-width: 450px) {
        .modal-mask{font-size: 2.5vw;}
        .modal-content-container{width: 115vw;}
    }
    @media only screen and (max-width: 350px) {
        .modal-mask{font-size: 3.5vw;}
    }
</style>
